<template>
  <b-col cols="2" class="edit-activated-container">
    <b-button block v-b-modal.edit-future-gr-modal variant="outline-secondary">Edit</b-button>

    <b-modal
      id="edit-future-gr-modal"
      title="Edit"
      @ok="handleOk"
      @show="onShow"
      @hidden="onHidden"
      no-close-on-backdrop
      v-model="modalShow"
      body-class="position-static"
      size="lg"
      :no-enforce-focus="noEnforceFocus"
    >
      <b-overlay :show="showOverlay" no-wrap rounded="sm" />
      <b-form @submit.stop.prevent="onSubmit" v-if="activeEvent && canEditFutureRaffles">
        <Alert v-if="formErrorMessage" variant="red" dismissible> {{ formErrorMessage }} </Alert>

        <b-form-row>
          <b-form-group label="Name" label-for="name" class="col" :invalid-feedback="veeErrors.first('name')">
            <b-form-input
              name="name"
              v-model="name"
              v-validate="{ min: 3, max: 120 }"
              :state="validateState('name')"
              aria-describedby="name-feedback"
              data-vv-as="name"
            />
          </b-form-group>

          <b-form-group
            label-for="input-draw-type"
            :invalid-feedback="veeErrors.first('input-draw-type')"
            description=""
            class="col"
          >
            <template slot="label">
              Type of Draw
              <sup
                v-b-tooltip.hover.top="
                  'If using Random Number generator, ensure you have approval from your provincial regulator.'
                "
              >
                <span class="fas fa-info-circle"></span>
              </sup>
            </template>
            <b-form-select
              name="input-draw-type"
              :state="validateState('input-draw-type')"
              v-model="drawType"
              aria-describedby="input-draw-type-feedback"
              :options="drawTypeOptions"
              v-validate="{ required: true }"
              data-vv-as="draw type"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group
            label="License Number"
            label-for="input-license-number"
            :invalid-feedback="veeErrors.first('input-license-number')"
            label-class="mb-0"
            ref="licenseNumber"
            class="col-6"
          >
            <b-form-input
              name="input-license-number"
              v-model="licenseNumber"
              v-validate.bail="'min:5'"
              :state="validateState('input-license-number')"
              aria-describedby="input-license-number-feedback"
              data-vv-as="license number"
            />
          </b-form-group>
          <b-form-group
            label="Draw Location"
            label-for="input-draw-location"
            :invalid-feedback="veeErrors.first('input-draw-location')"
            label-class="mb-0"
            ref="drawLocation"
            class="col"
          >
            <b-form-input
              name="input-draw-location"
              v-model="drawLocation"
              v-validate.bail="'min:2'"
              :state="validateState('input-draw-location')"
              aria-describedby="input-draw-location-feedback"
              data-vv-as="draw location"
            />
          </b-form-group>
        </b-form-row>

        <RbToggle
          v-model="supportStatsShow"
          :state="validateState('input-support-stats-show')"
          name="input-support-stats-show"
          label="Display Top Fundraisers"
          variant="success"
          @change="supportStatsShow = $event"
        />
        <RbToggle
          v-model="rulesEnabled"
          :state="validateState('input-rules-enabled')"
          name="input-rules-enabled"
          label="Display Rules"
          variant="success"
          @change="rulesEnabled = $event"
        />
        <b-form-row>
          <b-form-group
            label="Rules"
            label-for="input-rules-and-regs"
            :invalid-feedback="veeErrors.first('input-rules-and-regs')"
            label-class="mb-0"
            class="col"
          >
            <trumbowyg
              name="input-rules-and-regs"
              v-model="rules"
              v-validate="{ min: 3 }"
              :state="validateState('input-rules-and-regs')"
              aria-describedby="input-rules-and-regs"
              data-vv-as="rules and regulations"
              class="form-control"
              :config="config"
              @tbw-focus="toggleFocus"
            >
            </trumbowyg>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group
            label="Logo"
            label-for="input-logo-url"
            :invalid-feedback="veeErrors.first('input-logo-url')"
            label-class="mb-0"
            class="col"
          >
            <ImageUploadCropper
              :aspectRatio="{ aspectRatio: 16 / 9 }"
              :imageUrl="logoUrl"
              :uploadError="raffleImageUploadError"
              :uploadSuccess="raffleImageUploadSuccess"
              @uploadImage="uploadRaffleImage"
              @uploadImageError="uploadRaffleImageError"
              imageName="raffleLogo"
              class="py-5"
            />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Ticket Footer" label-for="ticket-footer" class="col">
            <b-form-textarea v-model="ticketFooter" name="ticket-footer" data-vv-as="ticket footer" />
          </b-form-group>
        </b-form-row>
      </b-form>
      <Alert v-if="!canEditFutureRaffles" variant="red" dismissible> No editable raffles found </Alert>
    </b-modal>
  </b-col>
</template>

<script>
import goldrushServiceV2 from '@/lib/goldrush-service-v2';
import eventServiceV2 from '@/lib/event-service-v2';
import FileUploadServiceV2 from '@/lib/file-upload-service-v2';
import ImageUploadCropper from '@/components/forms/ImageUploadCropper';
import trumbowygConfig from '@/trumbowygConfig';
import Alert from '@/components/ui/Alert';

export default {
  components: {
    ImageUploadCropper,
    Alert
  },
  props: {
    id: {
      type: String,
      required: true
    },
    canEditFutureRaffles: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      noEnforceFocus: false,
      name: '',
      drawType: '',
      licenseNumber: '',
      rules: '',
      rulesEnabled: null,
      supportStatsShow: null,
      drawLocation: '',
      logoUrl: '',
      formErrorMessage: '',
      loadingErrorMessage: '',
      activeEvent: null,
      modalShow: false,
      isSubmitting: false,
      ticketFooter: '',
      config: trumbowygConfig.getConfig(),
      raffleImageUploadSuccess: null,
      raffleImageUploadError: null,
      drawTypeOptions: [
        { value: 'barrel', text: 'Print Tickets' },
        { value: 'rng', text: 'Random Number Generator' }
      ]
    };
  },
  computed: {
    showOverlay() {
      return this.isSubmitting || (!this.activeEvent && this.canEditFutureRaffles);
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    toggleFocus() {
      if (!this.noEnforceFocus) {
        this.noEnforceFocus = true;
      }
    },

    uploadRaffleImageError(error) {
      this.raffleImageUploadError = error?.message;
    },

    async uploadRaffleImage(payload) {
      const formData = new FormData();

      formData.append('image', payload.blob, payload.name);

      try {
        const url = await FileUploadServiceV2.uploadFile(formData);
        this.logoUrl = url;
        this.raffleImageUploadSuccess = true;
        this.raffleImageUploadError = null;
        this.editRaffleFailed = false;
        this.formErrorMessage = null;
      } catch (error) {
        this.raffleImageUploadError = error?.message;
        this.editRaffleFailed = true;
        this.formErrorMessage = 'Could not upload image';
      }
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      this.isSubmitting = true;

      try {
        await goldrushServiceV2.updateEvents({
          goldrushMasterEventId: this.id,
          name: this.name,
          rules: this.rules,
          logoUrl: this.logoUrl,
          drawType: this.drawType,
          licenseNumber: this.licenseNumber,
          ticketFooter: this.ticketFooter,
          supportStatsShow: this.supportStatsShow,
          rulesEnabled: this.rulesEnabled,
          drawLocation: this.drawLocation
        });

        this.modalShow = false;

        this.$emit('updated');
      } catch (error) {
        if (error.response) {
          this.formErrorMessage = `Failed to edit future raffles: ${error.response.data.errors[0].message}`;
        } else {
          this.formErrorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
      this.isSubmitting = false;
    },
    closeTrumbowygModal() {
      const trumboModal = document.getElementsByClassName('trumbowyg-modal');

      if (trumboModal.length != 0) {
        trumboModal[0].classList.add('d-none');
      }
    },
    async resetForm() {
      this.closeTrumbowygModal();
      this.masterEvent = await goldrushServiceV2.readMasterEvent(this.id);
      this.name = this.activeEvent?.name;
      this.rules = this.activeEvent?.rules;
      this.logoUrl = this.activeEvent?.logoUrl;
      this.drawType = this.activeEvent?.drawType;
      this.licenseNumber = this.activeEvent?.licenseNumber;
      this.ticketFooter = this.activeEvent?.ticketFooter;
      this.supportStatsShow = this.activeEvent?.supportStatsShow;
      this.rulesEnabled = this.activeEvent?.rulesEnabled;
      this.drawLocation = this.activeEvent?.drawLocation;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    async onShow() {
      if (!this.canEditFutureRaffles) {
        return;
      }

      try {
        const response = await eventServiceV2.listEvents({
          status: 'active',
          goldrush: true,
          sortBy: 'endDate',
          page: 0,
          pageSize: 1
        });

        const event = response.data[0];

        this.activeEvent = event;

        if (!this.activeEvent) {
          this.loadingErrorMessage = 'No active event found';
        } else {
          this.resetForm();
        }
      } catch (error) {
        this.loadingErrorMessage = 'Failed to load active event: ' + error.message;
      }
    },

    async onHidden() {
      this.activeEvent = null;
      this.closeTrumbowygModal();
    }
  }
};
</script>

<style scoped>
.edit-activated-container {
  margin-bottom: 1rem;
}

.trumbowyg-box {
  margin-top: 0;
}
</style>
